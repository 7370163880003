<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="4">
              <b-form-group label="ФИО">
                <b-form-input
                  v-model="filter.fio"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('fio')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                class="form-group-el-select"
                label="Доверенность"
              >
                <el-select
                  v-model="filter.attorney"
                  class="form-control"
                  name="attorney"
                  placeholder=""
                  :loading="loading"
                  @input="updateFilter('attorney')"
                >
                  <el-option
                    v-for="item in attorneyOptions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                class="form-group-el-select"
                label="Допуск"
              >
                <el-select
                  v-model="filter.status"
                  class="form-control"
                  name="status"
                  placeholder=""
                  :loading="loading"
                  @input="updateFilter('status')"
                >
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Водители
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
          <b-button
            v-if="$store.getters.isAdminOrManager"
            variant="primary"
            size="sm"
            to="/drivers/add"
            class="ml-2"
          >
            <i class="fa fa-plus" />&nbsp;Добавить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="driver.fio"
            slot-scope="props"
          >{{ props.row.driver.surname+" "+props.row.driver.name }}<span v-if="props.row.driver.patronymic">{{ " "+props.row.driver.patronymic }}</span></span>
          <span
            slot="attorneys"
            slot-scope="props"
          >
            <span
              v-for="(attorney, index) in props.row.attorneys"
              :key="`attorney-${index}`"
            >
              <span><a :href="`/contractors/id${$store.state.user.ownerId}/attorney/id${attorney.id}`">№{{ attorney.number }}</a> {{ formatDate(attorney.dateStart) }} - {{ formatDate(attorney.dateEnd) }}</span><br>
              <p v-if="attorney.file">
                <a
                  :href="'/files/'+attorney.file.file.guid"
                  target="_blank"
                  @click.prevent="downloadFile(attorney.file.file)"
                >Скачать</a>
              </p>
            </span>
          </span>
          <span
            slot="driver.status"
            slot-scope="props"
          >
            <span
              v-if="props.row.driver.status === 0"
              class="text-secondary"
            >Новый &nbsp;</span>
            <span
              v-if="props.row.driver.status === 1"
              class="text-success"
            >Допущен</span>
            <span
              v-else-if="props.row.driver.status === 2"
              class="text-warning"
            >На верификации СБ</span>
            <span
              v-else-if="props.row.driver.status === 4"
              class="text-primary"
            >Проверка логистом</span>
            <span
              v-else-if="props.row.driver.status === 5"
              class="text-danger"
            >Отклонен</span>&ensp;

            <div v-if="props.row.driver.status === 3 && props.row.driver.blockedSecurity &&
                props.row.driver.validationStatus === 'Проверка выполнена' && 
                props.row.driver.decisionOnDriver === 'Нет решения'"
            >
                <span
                class="text-danger"
              >Проверка завершена. Водитель будет допущен к рейсу при условии предоставления следующих документов (инст-ция водителя, инф. лист, лист инструктажа, фото водителя). Заполняются водителем на складе загрузки.</span>
            </div>

            <div v-else>
                <span
                v-if="props.row.driver.status === 3"
                class="text-danger"
              >Не допущен &ensp;</span>
              <span
                v-if="props.row.driver.blockedSecurity"
                class="badge badge-danger"
              >Блокировка СБ &ensp;</span>
              <span
                v-if=" props.row.driver.validationStatus === 'Проверка выполнена' && props.row.driver.decisionOnDriver === 'Нет решения'"
                class="text-danger font-weight-bold"
              >
                Не хватает документов для принятия решения &ensp;
              </span>
            </div>
          </span>
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-if="props.row.driver.status === 0 && $store.getters.isAdminOrManager"
              v-b-tooltip.hover.bottom="'Отправить на проверку'"
              class="fa fa-mail-forward fa-lg text-success table-action-button"
              @click="contractorsDriverToCheck(props.row.id)"
            />
            <a
              v-if="(props.row.driver.status === 0 || props.row.driver.status === 1 || props.row.driver.status === 3) && $store.getters.isAdminOrManager"
              v-b-tooltip.hover.bottom="'Редактировать'"
              :href="`/drivers/driver/id${props.row.id}`"
              class="fa fa-pencil fa-lg text-primary table-action-button"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              :href="`/contractors/id${$store.state.user.ownerId}/drivers/id${props.row.id}`"
              class="fa fa-eye fa-lg text-secondary table-action-button"
            />
            <a
              v-if="(props.row.driver.status === 0 || props.row.driver.status === 1 || props.row.driver.status === 3 || props.row.driver.status === 5) && $store.getters.isAdmin"
              v-b-tooltip.hover.bottom="'Удалить'"
              class="fa fa-close fa-lg text-danger table-action-button"
              @click="confirmContractorsDriverDelete(props.row.id)"
            />
            <driver-inn-edit
              v-if="!props.row.driver.isCancel &&
                (props.row.driver.status === 0 || props.row.driver.status === 1) &&
                $store.getters.isAdminOrManager"
              v-model="props.row.driver.inn"
              :driver-id="props.row.id"
              minimal
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {
  contractorsDriverList,
  contractorsDriverToCheck,
  contractorsDriverDelete,
  downloadFile,
} from '../../services/api';
import moment from 'moment';
import {queryToUrl, URLToQuery} from '../../services/http';
import DriverInnEdit from '@/components/DriverInnEdit';
Vue.use(ServerTable);

export default {
  name: 'Drivers',
  components: {
    DriverInnEdit,
    ServerTable,
    Event,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      filter: {
        fio: '',
        attorney: null,
        status: null,
      },
      attorneyOptions: [
        {value: null, text: 'Все'},
        {value: 1, text: 'Действует'},
        {value: 0, text: 'Не действует'},
      ],
      statusOptions: [
        {value: null, text: 'Все'},
        {value: 1, text: 'Допущены к рейсам'},
        {value: 2, text: 'На верификации СБ'},
        {value: 0, text: 'Новые'},
        {value: 3, text: 'Не допущенные'},
        {value: 4, text: 'На проверке логистом'},
        {value: 5, text: 'Отклоненые логистом'},
      ],
      loading: false,
      columns: [
        'id',
        'driver.fio',
        'driver.inn',
        'attorneys',
        'driver.status',
        'driver.validationStatus',
        'driver.decisionOnDriver',
        'actions',
      ],
      data: [],
      options: {
        customFilters: ['fio', 'attorney', 'status'],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return contractorsDriverList(this.$store.state.user.ownerId, Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'id': 'ID',
          'driver.fio': 'ФИО',
          'attorneys': 'Доверенности',
          'driver.status': 'Допуск',
          'driver.validationStatus': 'Состояние проверки',
          'driver.decisionOnDriver': 'Решение проверки',
          'driver.inn': 'ИНН',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  created() {
    this.initRouteParams();
  },
  mounted() {
    const queries = this.$router.currentRoute.query;
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on('vue-tables.pagination', (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  methods: {
    downloadFile,
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    async contractorsDriverToCheck(contractorDriverId) {
      this.loading = true;
      const response = await contractorsDriverToCheck(this.$store.state.user.ownerId, contractorDriverId);
      if (response && response.status === 200) {
        this.showSuccess('Водитель отправлен на первичную проверку');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmContractorsDriverDelete(contractorDriverId) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить водителя?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.contractorsDriverDelete(contractorDriverId);
          }
        });
    },
    async contractorsDriverDelete(contractorDriverId) {
      this.loading = true;
      const response = await contractorsDriverDelete(
        this.$store.state.user.ownerId,
        contractorDriverId,
      );
      if (response && response.status === 200) {
        this.showSuccess('Водитель удален');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.fio) {
        res.fio = this.filter.fio;
      }
      if (this.filter.attorney !== null) {
        res.attorney = Number(this.filter.attorney);
      }
      if (this.filter.status !== null) {
        res.status = Number(this.filter.status);
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: this.filter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('attorney')) {
          params.attorney = Number(params.attorney);
        }
        if (params.hasOwnProperty('status')) {
          params.status = Number(params.status);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        Object.assign(this.filter, params);
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        if (params) {
          Object.assign(this.filter, params);
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>
